export enum SelectJDSection {
  SELECT_JD = 'select-jd',
  IMPORT_JD = 'import-jd',
}

export const AddJobOptions = [
  {
    key: SelectJDSection.IMPORT_JD,
    icon: 'fi-rr-upload',
    heading: 'Import a Job X-Ray',
    description: 'Job Description Added',
  },
  {
    key: SelectJDSection.SELECT_JD,
    icon: 'fi-rr-document-signed',
    heading: 'Select a Job X-Ray',
    description: '',
  },
];
