import { useLayoutEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { Stack, Typography, useTheme } from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import ProgressManager from '../../components/ProgressManager';
import { ProgressScreenType } from '../../components/ProgressManager/constants';
import ResumeSelector from '../../components/ResumeSelector';
import SelectJobDescription from '../../components/SelectJobDescription';
import BreadCrumbs from '../../components/common/BreadCrumbs';
import Button from '../../components/common/Button';
import Stepper from '../../components/common/Stepper';
import { useSnackbar } from '../../contexts/snackbar';
import useResponsiveDevice from '../../hooks/useResponsiveDevice';
import RoutePaths from '../../routes/RoutePaths';
import { JobDescriptionTypes, uploadJobDescription } from '../../services/jobDescriptions';
import { createNewJobMatchScore } from '../../services/jobMatches';
import { ResumesKeys, uploadJdResume, uploadResumeFile } from '../../services/resumes';
import { useAppBarStore } from '../../stores/AppBarStore';
import { isNilOrEmpty, isNotNilOrEmpty } from '../../utils';
import { SelectJDSection } from './components/SelectJobDescriptionDeprecated';
import { SelectResumeSection } from './components/SelectResume';

interface JobMatchDetailsProps {
  jobDescription: {
    activeSection: SelectJDSection;
    jobDescriptionId: string;
    jobDescriptionText: string;
  };
  resume: {
    activeSection: SelectResumeSection;
    resumeId: string;
    resumeFile: File | undefined;
  };
}

const CreateJobMatch = () => {
  const navigate = useNavigate();
  const { showSnackbar } = useSnackbar();
  const [activeStep, setActiveStep] = useState(1);
  const { isMobile } = useResponsiveDevice();
  const { setAppBar, resetToDesktopAppBar, setInitialState } = useAppBarStore();

  // query client & mutations
  const queryClient = useQueryClient();
  const location = useLocation();
  const jobMatchScoreId = location?.state?.matchScoreId;
  const theme = useTheme();

  const [matchScoreId, setMatchScoreId] = useState<string>(jobMatchScoreId || '');

  const [jobMatchDetails, setJobMatchDetails] = useState<JobMatchDetailsProps>({
    jobDescription: {
      activeSection: SelectJDSection.SELECT_JD,
      jobDescriptionId: '',
      jobDescriptionText: '',
    },
    resume: {
      activeSection: SelectResumeSection.SELECT_RESUME,
      resumeId: '',
      resumeFile: undefined,
    },
  });

  const [isShowingProgress, setIsShowingProgress] = useState(
    isNotNilOrEmpty(location?.state?.matchScoreId),
  );

  const BreadCrumbLinks = isMobile
    ? [
        {
          label: 'Back',
          href: '/',
        },
      ]
    : [
        {
          label: 'Job Matches',
          href: RoutePaths.JOBMATCHES,
        },
        {
          label: 'Create',
          href: RoutePaths.CREATE_JOB_MATCH,
        },
      ];

  useLayoutEffect(() => {
    if (isMobile) {
      setAppBar('Job Matches', null);
    } else {
      resetToDesktopAppBar();
    }
    return () => {
      setInitialState(isMobile);
    };
  }, [isMobile, resetToDesktopAppBar, setAppBar, setInitialState]);

  const { isLoading: isCreateJobMatchLoading, mutate: createJobMatch } = useMutation({
    retry: 3,
    mutationFn: createNewJobMatchScore,
    onSuccess: (res) => {
      const jobMatchData = res?.data;
      setMatchScoreId(jobMatchData.match_score_id);
      setIsShowingProgress(true);
    },
  });

  const { isLoading, mutate: uploadJD } = useMutation({
    retry: 3,
    mutationFn: uploadJobDescription,
    onSuccess: (jdUploadData) => {
      jobMatchDetails.jobDescription.jobDescriptionId = jdUploadData._id;
      queryClient.invalidateQueries([JobDescriptionTypes.JOBXRAYS]); // invalidate, so that updated job xray are fetched
      setTimeout(() => {
        createJobMatch({
          job_description_id: jdUploadData._id,
          resume_id: jobMatchDetails.resume.resumeId,
        });
      }, 500);
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onError: (error: any) => {
      showSnackbar(
        'error',
        error.response?.data?.errors || 'Failed to upload Job Description. Please try again.',
      );
    },
  });

  const { isLoading: isResumeLoading, mutate: uploadResume } = useMutation({
    retry: 3,
    mutationFn: uploadResumeFile,
    onSuccess: (resumeUploadData) => {
      jobMatchDetails.resume.resumeId = resumeUploadData._id;
      queryClient.invalidateQueries([ResumesKeys.RESUMES]); // invalidate, so that updated resumes are fetched
      // TODO: need to verify setTimeout.
      setTimeout(() => {
        createJobMatch({
          job_description_id: jobMatchDetails.jobDescription.jobDescriptionId,
          resume_id: resumeUploadData._id,
        });
      }, 500);
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onError: (error: any) => {
      showSnackbar(
        'error',
        error.response?.data?.errors || 'Failed to upload Resume. Please try again.',
      );
    },
  });

  const uploadJdResumeQueryFuntion = async ({
    jobDescription,
    resume,
  }: {
    jobDescription: string;
    resume: File;
  }) => {
    const res = await uploadJdResume(jobDescription, resume);
    return res;
  };

  const { mutate: uploadJdResumeData } = useMutation({
    retry: 3,
    mutationFn: uploadJdResumeQueryFuntion,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onSuccess: (jdResumeData: any) => {
      queryClient.invalidateQueries([ResumesKeys.RESUMES]); // invalidate, so that updated resumes are fetched
      queryClient.invalidateQueries([JobDescriptionTypes.JOBXRAYS]); // invalidate, so that updated job xray are fetched
      jobMatchDetails.jobDescription.jobDescriptionId = jdResumeData.job_description_id;
      jobMatchDetails.resume.resumeId = jdResumeData.resume_id;
      // TODO: need to verify setTimeout.
      setTimeout(() => {
        createJobMatch({
          job_description_id: jdResumeData.job_description_id,
          resume_id: jdResumeData.resume_id,
          should_ai_customize_resume: false,
        });
      }, 500);
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onError: (error: any) => {
      showSnackbar(
        'error',
        error.response?.data?.errors ||
          'Failed to upload Resume or Job Description. Please try again.',
      );
    },
  });

  const onClickNext = () => {
    if (
      activeStep === 1 &&
      (jobMatchDetails.jobDescription.jobDescriptionId ||
        jobMatchDetails.jobDescription.jobDescriptionText)
    ) {
      setActiveStep(2);
    }

    if (activeStep === 2) {
      if (
        jobMatchDetails.resume.activeSection === SelectResumeSection.SELECT_RESUME &&
        jobMatchDetails.resume.resumeId &&
        jobMatchDetails.jobDescription.activeSection === SelectJDSection.SELECT_JD &&
        jobMatchDetails.jobDescription.jobDescriptionId
      ) {
        createJobMatch({
          job_description_id: jobMatchDetails.jobDescription.jobDescriptionId,
          resume_id: jobMatchDetails.resume.resumeId,
        });
      } else if (
        jobMatchDetails.resume.activeSection === SelectResumeSection.SELECT_RESUME &&
        jobMatchDetails.resume.resumeId &&
        jobMatchDetails.jobDescription.activeSection === SelectJDSection.IMPORT_JD &&
        jobMatchDetails.jobDescription.jobDescriptionText
      ) {
        uploadJD(jobMatchDetails.jobDescription.jobDescriptionText);
      } else if (
        jobMatchDetails.resume.activeSection === SelectResumeSection.IMPORT_RESUME &&
        jobMatchDetails.resume.resumeFile &&
        jobMatchDetails.jobDescription.activeSection === SelectJDSection.SELECT_JD &&
        jobMatchDetails.jobDescription.jobDescriptionId
      ) {
        uploadResume(jobMatchDetails.resume.resumeFile);
      } else if (
        jobMatchDetails.resume.activeSection === SelectResumeSection.IMPORT_RESUME &&
        jobMatchDetails.resume.resumeFile &&
        jobMatchDetails.jobDescription.activeSection === SelectJDSection.IMPORT_JD &&
        jobMatchDetails.jobDescription.jobDescriptionText
      ) {
        uploadJdResumeData({
          jobDescription: jobMatchDetails.jobDescription.jobDescriptionText,
          resume: jobMatchDetails.resume.resumeFile,
        });
      }
    }
  };

  const onClickBack = () => {
    if (activeStep === 1) {
      navigate(-1);
    } else {
      setActiveStep(activeStep - 1);
    }
  };

  const shouldDisableNextButton = useMemo(
    () => () =>
      (activeStep === 1 &&
        isNilOrEmpty(jobMatchDetails.jobDescription.jobDescriptionId) &&
        isNilOrEmpty(jobMatchDetails.jobDescription.jobDescriptionText)) ||
      (activeStep === 2 &&
        isNilOrEmpty(jobMatchDetails.resume.resumeId) &&
        isNilOrEmpty(jobMatchDetails.resume.resumeFile)) ||
      isLoading ||
      isResumeLoading ||
      isCreateJobMatchLoading,
    [activeStep, isCreateJobMatchLoading, isLoading, isResumeLoading, jobMatchDetails],
  );

  // TODO: needs refactoring and respelling
  // TODO: rework required to handle scenarios in which the user removes anything especially resume file (bug)

  const onUpdateJobDescription = async (data: { jdId?: string; jDText?: string }) => {
    if (data.jdId) {
      setJobMatchDetails((prevJobDescription) => ({
        ...prevJobDescription,
        jobDescription: {
          activeSection: SelectJDSection.SELECT_JD,
          jobDescriptionId: data.jdId || '',
          jobDescriptionText: prevJobDescription.jobDescription.jobDescriptionText,
        },
      }));
      if (isMobile) {
        setActiveStep(2);
      }
    }

    if (data.jDText) {
      setJobMatchDetails((prevJobDescription) => ({
        ...prevJobDescription,
        jobDescription: {
          activeSection: SelectJDSection.IMPORT_JD,
          jobDescriptionText: data.jDText || '',
          jobDescriptionId: prevJobDescription.jobDescription.jobDescriptionId,
        },
      }));
      if (isMobile) {
        setActiveStep(2);
      }
    }
  };

  const onUpdateResume = (data: { resumeId?: string; resumeFile?: File }) => {
    if (data.resumeId) {
      setJobMatchDetails((prevJobDescription) => ({
        ...prevJobDescription,
        resume: {
          activeSection: SelectResumeSection.SELECT_RESUME,
          resumeId: data.resumeId || '',
          resumeFile: prevJobDescription.resume.resumeFile,
        },
      }));
      if (isMobile) {
        if (jobMatchDetails.jobDescription.activeSection === SelectJDSection.SELECT_JD) {
          createJobMatch({
            job_description_id: jobMatchDetails.jobDescription.jobDescriptionId,
            resume_id: data.resumeId || '',
          });
        } else {
          uploadJD(jobMatchDetails.jobDescription.jobDescriptionText);
        }
      }
    }

    if (data.resumeFile) {
      setJobMatchDetails((prevJobDescription) => ({
        ...prevJobDescription,
        resume: {
          activeSection: SelectResumeSection.IMPORT_RESUME,
          resumeId: prevJobDescription.resume.resumeId || '',
          resumeFile: data.resumeFile,
        },
      }));
      if (isMobile) {
        if (jobMatchDetails.jobDescription.activeSection === SelectJDSection.SELECT_JD) {
          uploadResume(data.resumeFile);
        } else {
          uploadJdResumeData({
            jobDescription: jobMatchDetails.jobDescription.jobDescriptionText,
            resume: data.resumeFile,
          });
        }
      }
    }
  };

  return (
    // create Job score page
    <Stack
      sx={{
        height: '100%',
        width: '100%',
        maxWidth: '120rem',
        padding: {
          xs: `${theme.spacing(3)} ${theme.spacing(1.5)}`,
          sm: `${theme.spacing(3)}`,
        },
        boxSizing: 'border-box',
        gap: { xs: 1.5, sm: 4 },
      }}
    >
      {!isShowingProgress ? (
        <>
          <Stack sx={{ gap: 0.5 }}>
            <BreadCrumbs links={BreadCrumbLinks} onBack={onClickBack} />
            {!isMobile && (
              <Typography variant="body2" sx={{ paddingLeft: 3.75 }}>
                Create a Job Match Score Report.
              </Typography>
            )}
          </Stack>
          <Stack
            sx={{
              paddingX: { xs: 0, sm: 3.75 },
              gap: { xs: 1.5, sm: 4 },
              height: '100%',
              justifyContent: 'space-between',
            }}
          >
            <Stack sx={{ gap: 4 }}>
              <Stepper totalSteps={2} activeStep={activeStep} />
              {/* sections */}
              {activeStep === 1 ? (
                <SelectJobDescription
                  onUpdate={onUpdateJobDescription}
                  value={jobMatchDetails.jobDescription}
                />
              ) : (
                <ResumeSelector onUpdate={onUpdateResume} value={jobMatchDetails.resume} />
              )}
            </Stack>
            {/* Next button goes here */}
            <Stack
              sx={{
                gap: 1.5,
                flexDirection: 'row',
                justifyContent: 'flex-end',
              }}
            >
              <Button
                variant="outlined"
                onClick={onClickBack}
                sx={{
                  width: { xs: '30%', sm: 'fit-content' },
                  padding: {
                    xs: `${theme.spacing(2)} ${theme.spacing(1.5)}`,
                    sm: `${theme.spacing(1)} ${theme.spacing(1.5)}`,
                  },
                }}
              >
                {activeStep === 1 ? 'Cancel' : 'Back'}
              </Button>
              <Button
                onClick={onClickNext}
                sx={{
                  width: { xs: '70%', sm: 'fit-content' },
                  padding: {
                    xs: `${theme.spacing(2)} ${theme.spacing(1.5)}`,
                    sm: `${theme.spacing(1)} ${theme.spacing(1.5)}`,
                  },
                }}
                disabled={shouldDisableNextButton()}
                loading={isLoading || isResumeLoading}
              >
                Next
              </Button>
            </Stack>
          </Stack>
        </>
      ) : (
        <ProgressManager id={matchScoreId} progressScreenKey={ProgressScreenType.JobMatchScore} />
      )}
    </Stack>
  );
};

export default CreateJobMatch;
