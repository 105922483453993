import { ResumeKey } from '../interface';
import { AutoFixModes } from '../stores/SuperEditorDrafts';

type ModeSections = ResumeKey.OBJECTIVE | ResumeKey.SUMMARY | ResumeKey.EXPERIENCE;

/**
 * An object that defines the available modes for each section.
 */
export const autoFixModes: Record<ModeSections, AutoFixModes[]> = {
  [ResumeKey.OBJECTIVE]: [AutoFixModes.Boring, AutoFixModes.Fun],
  [ResumeKey.SUMMARY]: [AutoFixModes.Boring, AutoFixModes.Fun],
  [ResumeKey.EXPERIENCE]: [AutoFixModes.Boring, AutoFixModes.Number, AutoFixModes.Fun],
};

/**
 * Returns the next mode for a given section based on the current mode.
 *
 * @param {ModeSections} section - The section for which to get the next mode.
 * @param {AutoFixModes} currentMode - The current mode of the section.
 * @returns {AutoFixModes} The next mode for the section.
 *
 * @example
 * const autoFixNextMode = getAutoFixNextMode(ResumeKey.OBJECTIVE, AutoFixModes.Boring);
 * console.log(autoFixNextMode); // Output: AutoFixModes.Fun
 */
export const getAutoFixNextMode = (
  section: ModeSections,
  currentMode: AutoFixModes,
): AutoFixModes => {
  const currentIndex = autoFixModes[section].indexOf(currentMode);
  const nextIndex = (currentIndex + 1) % autoFixModes[section].length;
  return autoFixModes[section][nextIndex];
};
