import { useLayoutEffect, useMemo } from 'react';

import Grid2 from '@mui/material/Unstable_Grid2';

import type { Draft as DraftDataType } from '../../../../stores/SuperEditorDrafts';
import useDraftsStore from '../../../../stores/SuperEditorDrafts';
import Draft from './components/Draft';

interface DraftsManagerProps {
  sectionTitle?: string;
  onRemoveDraft?: (id: string) => void;
  onAppendDraft?: (draft: DraftDataType) => void;
  onReplaceDraft?: (draft: DraftDataType) => void;
}

/**
 * The `DraftsManager` component manages and displays a list of drafts.
 * It provides functionality to append, replace, and remove drafts.
 *
 * @component
 *
 * @param {DraftsManagerProps} props - The props for the `DraftsManager` component.
 * @param {string} [props.sectionTitle] - The title of the section the drafts belong to.
 * @param {(id: string) => void} [props.onRemoveDraft] - Optional handler for removing a draft by its ID.
 * @param {(draft: DraftDataType) => void} [props.onAppendDraft] - Optional handler for appending a draft.
 * @param {(draft: DraftDataType) => void} [props.onReplaceDraft] - Optional handler for replacing a draft.
 *
 * @returns {JSX.Element} The rendered `DraftsManager` component.
 *
 * @example
 * <DraftsManager
 *   sectionTitle="My Drafts"
 *   onRemoveDraft={(id) => console.log('Remove draft with ID:', id)}
 *   onAppendDraft={(draft) => console.log('Append draft:', draft)}
 *   onReplaceDraft={(draft) => console.log('Replace draft:', draft)}
 * />
 */
const DraftsManager = ({
  sectionTitle = '',
  onRemoveDraft,
  onAppendDraft,
  onReplaceDraft,
}: DraftsManagerProps) => {
  const { drafts, removeDraft, resetDrafts } = useDraftsStore();

  const gridSize = useMemo(
    () => ({
      xs: 12,
      sm: 12 / (drafts.length > 2 ? 2 : drafts.length),
      lg: 12 / (drafts.length > 3 ? 3 : drafts.length),
    }),
    [drafts.length],
  );

  const onDelete = (id: string) => {
    removeDraft(id);
    if (onRemoveDraft) {
      onRemoveDraft(id);
    }
  };

  // cleanup code to reset drafts once component mounts for the first time and  before it unmounts
  useLayoutEffect(() => {
    resetDrafts();

    return () => {
      resetDrafts();
    };
  }, [resetDrafts]);

  return (
    <>
      {drafts?.length > 0 && (
        // Drafts UI
        <Grid2 container sx={{ justifyContent: 'center' }} spacing={1.5}>
          {drafts.map((draft) => (
            <Grid2 key={draft.id} {...gridSize}>
              {/*  Draft component */}
              <Draft
                draft={draft}
                sectionTitle={sectionTitle}
                onAppendDraft={onAppendDraft}
                onReplaceDraft={onReplaceDraft}
                onDelete={onDelete}
              />
            </Grid2>
          ))}
        </Grid2>
      )}
    </>
  );
};

export default DraftsManager;
