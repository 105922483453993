import { isNil, isNotNil } from 'ramda';
import { useCallback, useMemo, useState } from 'react';
import { useLocation, useMatch, useNavigate } from 'react-router-dom';

import { Chip, Divider, Stack, Typography, useTheme } from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import ProgressManager from '../../components/ProgressManager';
import { ProgressScreenType } from '../../components/ProgressManager/constants';
import BreadCrumbs from '../../components/common/BreadCrumbs';
import Button from '../../components/common/Button';
import Icon from '../../components/common/Icon';
import { useSnackbar } from '../../contexts/snackbar';
import useIsMobile from '../../hooks/useIsMobile';
import RoutePaths from '../../routes/RoutePaths';
import { uploadJobDescription } from '../../services/jobDescriptions';
import {
  GenerateAiCustomizeResume,
  ResumesKeys,
  uploadJdResume,
  uploadResumeFile,
} from '../../services/resumes';
import { useSideBarStore } from '../../stores/SideBarStore';
import SelectJobDescriptionDeprecated, {
  SelectJDSection,
} from '../Create/components/SelectJobDescriptionDeprecated';
import SelectResume, { SelectResumeSection } from '../Create/components/SelectResume';

// TODO: verify and update/remove this flow
// TODO: reafctor this component with common components
enum AiCustomizeResumeSection {
  SELECT_JD = 'select-jd',
  SELECT_RESUME = 'select-resume',
  AI_CUSTOMIZE_PROGRESS = 'ai-customize-progress',
}

const AiCustomizedResume = () => {
  const navigate = useNavigate();
  const match = useMatch(RoutePaths.AI_CUSTOMIZED);
  const { showSnackbar } = useSnackbar();
  const isMobile = useIsMobile();
  const { isOpen } = useSideBarStore();

  // query client & mutations
  const queryClient = useQueryClient();
  const location = useLocation();
  const filename = location?.state?.filename;
  const aiCustomizeId = location?.state?.aiCustomizeId;
  const theme = useTheme();

  // AI customize resume id is used for progress status API call
  const [aiCustomizedResumeId, setAiCustomizedResumeId] = useState<string>(aiCustomizeId || '');
  const [shouldDisplayBackButton, setShouldDisplayBackButton] = useState<Boolean>(false);

  const [jobDescriptionId, setJobDescriptionId] = useState<string>('');
  const [jdText, setJdText] = useState<string>('');
  const [resumeId, setResumeId] = useState<string>(
    isNotNil(filename) ? match?.params.id || '' : '',
  );
  const [resumeFile, setResumeFile] = useState<File>();

  const [selectedSection, setSelectedSection] = useState<AiCustomizeResumeSection>(
    // eslint-disable-next-line no-nested-ternary
    isNotNil(aiCustomizeId)
      ? AiCustomizeResumeSection.AI_CUSTOMIZE_PROGRESS
      : isNotNil(filename)
      ? AiCustomizeResumeSection.SELECT_JD
      : AiCustomizeResumeSection.SELECT_RESUME,
  );

  const [completedSections, setCompletedSections] = useState({
    [AiCustomizeResumeSection.SELECT_JD]: {
      status: false,
      section: SelectJDSection.SELECT_JD,
      value: jobDescriptionId,
    },
    [AiCustomizeResumeSection.SELECT_RESUME]: {
      status: isNotNil(filename),
      section: isNotNil(filename)
        ? SelectResumeSection.IMPORT_RESUME
        : SelectResumeSection.SELECT_RESUME,
      value: isNotNil(filename) ? resumeId : resumeFile,
    },
  });

  const AiCustomizeResume = useCallback(async (jdId: string, resId: string) => {
    try {
      if (jdId && resId) {
        const jdResumeId = {
          job_description_id: jdId,
          resume_id: resId,
        };
        // TODO: throlle the api call
        const res = await GenerateAiCustomizeResume(jdResumeId);
        setAiCustomizedResumeId(res._id);
        setSelectedSection(AiCustomizeResumeSection.AI_CUSTOMIZE_PROGRESS);
      }
    } catch (error) {
      /* empty */
    }
  }, []);

  // mutations
  const { isLoading: isJdUploading, mutate: uploadJD } = useMutation({
    retry: 3,
    mutationFn: uploadJobDescription,
    onSuccess: (jdData) => {
      queryClient.invalidateQueries([ResumesKeys.RESUMES]); // invalidate, so that updated resumes are fetched
      setJobDescriptionId(jdData._id);
      AiCustomizeResume(jdData._id, resumeId);
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onError: (error: any) => {
      showSnackbar(
        'error',
        error.response?.data?.errors || 'Failed to upload Job Description. Please try again.',
      );
    },
  });

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { isLoading: isResumeUploading, mutate: uploadResume } = useMutation({
    retry: 3,
    mutationFn: uploadResumeFile,
    onSuccess: (resumeData) => {
      queryClient.invalidateQueries([ResumesKeys.RESUMES]); // invalidate, so that updated resumes are fetched
      setResumeId(resumeData._id);
      AiCustomizeResume(jobDescriptionId, resumeData._id);
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onError: (error: any) => {
      showSnackbar(
        'error',
        error.response?.data?.errors || 'Failed to upload Resume. Please try again.',
      );
    },
  });

  const uploadJdResumeQueryFuntion = async () => {
    if (isNotNil(jobDescriptionId) && isNotNil(resumeFile)) {
      const res = await uploadJdResume(jdText, resumeFile);
      return res;
    }
    return new Error('Job Description and Resume file are required');
  };

  const { mutate: uploadJdResumeData } = useMutation({
    retry: 3,
    mutationFn: uploadJdResumeQueryFuntion,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onSuccess: (jdResumeData: any) => {
      queryClient.invalidateQueries([ResumesKeys.RESUMES]); // invalidate, so that updated resumes are fetched
      setJobDescriptionId(jdResumeData.job_description_id);
      setResumeId(jdResumeData.resume_id);
      AiCustomizeResume(jdResumeData.job_description_id, jdResumeData.resume_id);
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onError: (error: any) => {
      showSnackbar(
        'error',
        error.response?.data?.errors ||
          'Failed to upload Resume or Job Description. Please try again.',
      );
    },
  });

  const BreadCrumbLinks = [
    {
      label: 'Resumes',
      href: RoutePaths.RESUMES,
    },
    {
      label: 'AI Customize',
      href: '',
    },
  ];

  const onClickBack = () => {
    navigate(-1);
  };

  const shouldDisableNextButton = useMemo(
    () => () =>
      (selectedSection === AiCustomizeResumeSection.SELECT_JD &&
        !completedSections[AiCustomizeResumeSection.SELECT_JD].status) ||
      (selectedSection === AiCustomizeResumeSection.SELECT_RESUME &&
        !completedSections[AiCustomizeResumeSection.SELECT_RESUME].status),
    [completedSections, selectedSection],
  );

  // TODO: needs refactoring and respelling
  const handleOnSelected = (data: {
    jdId?: string;
    resumeId?: string;
    jDText?: string;
    resumeFile?: File;
  }) => {
    if (data.jdId) {
      setJobDescriptionId(data.jdId);
      setJdText('');
      setCompletedSections({
        ...completedSections,
        [AiCustomizeResumeSection.SELECT_JD]: {
          status: true,
          section: SelectJDSection.SELECT_JD,
          value: data.jdId,
        },
      });
    }

    if (data.resumeId) {
      setResumeId(data.resumeId);
      setCompletedSections({
        ...completedSections,
        [AiCustomizeResumeSection.SELECT_RESUME]: {
          status: true,
          section: SelectResumeSection.SELECT_RESUME,
          value: data.resumeId,
        },
      });
    }

    if (data.jDText) {
      setJdText(data.jDText);
      setJobDescriptionId('');
      setCompletedSections({
        ...completedSections,
        [AiCustomizeResumeSection.SELECT_JD]: {
          status: true,
          section: SelectJDSection.IMPORT_JD,
          value: data.jDText,
        },
      });
    }

    if (data.resumeFile) {
      setResumeId('');
      setResumeFile(data.resumeFile);
      setCompletedSections({
        ...completedSections,
        [AiCustomizeResumeSection.SELECT_RESUME]: {
          status: true,
          section: SelectResumeSection.IMPORT_RESUME,
          value: data.resumeFile,
        },
      });
    }
  };

  const onClickNext = () => {
    if (selectedSection === AiCustomizeResumeSection.SELECT_RESUME && (resumeId || resumeFile)) {
      setSelectedSection(AiCustomizeResumeSection.SELECT_JD);
      setShouldDisplayBackButton(true);
    }

    if (selectedSection === AiCustomizeResumeSection.SELECT_JD) {
      if (resumeId && jobDescriptionId) {
        AiCustomizeResume(jobDescriptionId, resumeId);
      } else if (resumeId && jdText) {
        uploadJD(jdText);
      } else if (resumeFile && jobDescriptionId) {
        uploadResume(resumeFile);
      } else if (resumeFile && jdText) {
        uploadJdResumeData();
      }
    }
  };

  const onClickBackButton = () => {
    setSelectedSection(AiCustomizeResumeSection.SELECT_RESUME);
    setShouldDisplayBackButton(false);
  };

  return (
    // quickfix: removing left margin added by layout component
    <Stack
      sx={{
        marginLeft:
          selectedSection === AiCustomizeResumeSection.AI_CUSTOMIZE_PROGRESS && !isOpen && !isMobile
            ? '20px'
            : '0px',
      }}
    >
      {/* create Job score page */}
      <Stack
        sx={{
          height: '100%',
          width:
            selectedSection === AiCustomizeResumeSection.AI_CUSTOMIZE_PROGRESS
              ? `calc(100% - ${isMobile ? '24px' : '48px'})`
              : '80vw',
          maxWidth: '1920px',
          margin: '32px auto',
        }}
      >
        {selectedSection !== AiCustomizeResumeSection.AI_CUSTOMIZE_PROGRESS && (
          <>
            <BreadCrumbs links={BreadCrumbLinks} onBack={onClickBack} />
            <Typography variant="body2" paddingLeft="30px">
              {isNotNil(filename)
                ? `Create an AI customized resume for "${filename}" with your selected or imported job.`
                : 'Tailor your resume to a job.'}
            </Typography>
          </>
        )}

        {selectedSection !== AiCustomizeResumeSection.AI_CUSTOMIZE_PROGRESS && isNil(filename) && (
          <>
            <Stack
              sx={{
                marginTop: 4,
                marginBottom: '12px',
                flexDirection: 'row',
                gap: 2,
              }}
            >
              {/* TODO: refactor and make generic Chip component */}
              <Chip
                label={
                  <Typography
                    variant={
                      selectedSection === AiCustomizeResumeSection.SELECT_RESUME
                        ? 'label1'
                        : 'body2'
                    }
                  >
                    Step 1
                  </Typography>
                }
                icon={
                  <Icon
                    className={
                      completedSections[AiCustomizeResumeSection.SELECT_RESUME].status &&
                      selectedSection !== AiCustomizeResumeSection.SELECT_RESUME
                        ? 'fi fi-br-check'
                        : ''
                    }
                    fontSize="16px"
                    color={
                      completedSections[AiCustomizeResumeSection.SELECT_RESUME].status &&
                      selectedSection !== AiCustomizeResumeSection.SELECT_RESUME
                        ? theme.palette.primary.main
                        : ''
                    }
                  />
                }
                sx={{
                  backgroundColor:
                    selectedSection === AiCustomizeResumeSection.SELECT_RESUME
                      ? 'primary.light'
                      : 'transparent',
                  color:
                    selectedSection === AiCustomizeResumeSection.SELECT_RESUME
                      ? 'primary.main'
                      : 'inherit',
                  border:
                    selectedSection !== AiCustomizeResumeSection.SELECT_RESUME ? 'none' : 'unset',
                  fontWeight:
                    selectedSection === AiCustomizeResumeSection.SELECT_RESUME ? 600 : 400,
                  borderRadius: '8px',
                  '.MuiChip-label': {
                    padding: '12px 8px',
                  },
                  '.MuiChip-icon': {
                    paddingRight:
                      selectedSection === AiCustomizeResumeSection.SELECT_RESUME ? 0 : 1,
                  },
                }}
                variant={
                  selectedSection === AiCustomizeResumeSection.SELECT_RESUME ? 'filled' : 'outlined'
                }
              />
              <Chip
                label={
                  <Typography
                    variant={
                      selectedSection === AiCustomizeResumeSection.SELECT_JD ? 'label1' : 'body2'
                    }
                  >
                    Step 2
                  </Typography>
                }
                icon={
                  <Icon
                    className={
                      completedSections[AiCustomizeResumeSection.SELECT_JD].status &&
                      selectedSection !== AiCustomizeResumeSection.SELECT_JD
                        ? 'fi fi-br-check'
                        : ''
                    }
                    fontSize="16px"
                    color={
                      completedSections[AiCustomizeResumeSection.SELECT_JD].status &&
                      selectedSection !== AiCustomizeResumeSection.SELECT_JD
                        ? theme.palette.primary.main
                        : ''
                    }
                  />
                }
                sx={{
                  backgroundColor:
                    selectedSection === AiCustomizeResumeSection.SELECT_JD
                      ? 'primary.light'
                      : 'transparent',
                  color:
                    selectedSection === AiCustomizeResumeSection.SELECT_JD
                      ? 'primary.main'
                      : 'inherit',
                  border: selectedSection !== AiCustomizeResumeSection.SELECT_JD ? 'none' : 'unset',
                  fontWeight: selectedSection === AiCustomizeResumeSection.SELECT_JD ? 600 : 400,
                  borderRadius: '8px',
                  '.MuiChip-label': {
                    padding: '12px 8px',
                  },
                  '.MuiChip-icon': {
                    paddingRight: selectedSection === AiCustomizeResumeSection.SELECT_JD ? 0 : 1,
                  },
                }}
                variant={
                  selectedSection === AiCustomizeResumeSection.SELECT_JD ? 'filled' : 'outlined'
                }
              />
            </Stack>

            <Divider />
          </>
        )}

        {/* sections */}
        {selectedSection === AiCustomizeResumeSection.SELECT_JD && (
          <SelectJobDescriptionDeprecated
            onSelected={handleOnSelected}
            defaultValue={{
              section: completedSections[AiCustomizeResumeSection.SELECT_JD].section,
              value: completedSections[AiCustomizeResumeSection.SELECT_JD].value,
            }}
          />
        )}

        {selectedSection === AiCustomizeResumeSection.SELECT_RESUME && (
          <SelectResume
            onSelected={handleOnSelected}
            defaultValue={{
              section: completedSections[AiCustomizeResumeSection.SELECT_RESUME].section,
              value: completedSections[AiCustomizeResumeSection.SELECT_RESUME].value,
            }}
          />
        )}

        {selectedSection === AiCustomizeResumeSection.AI_CUSTOMIZE_PROGRESS && (
          <ProgressManager
            id={aiCustomizedResumeId}
            progressScreenKey={ProgressScreenType.AiCustomize}
          />
        )}

        {/* Next button goes here */}
        {selectedSection !== AiCustomizeResumeSection.AI_CUSTOMIZE_PROGRESS && (
          <Stack
            sx={{
              flexDirection: 'row',
              marginTop: '32px',
              justifyContent: shouldDisplayBackButton ? 'space-between' : 'flex-end',
            }}
          >
            {shouldDisplayBackButton && (
              <Button variant="outlined" onClick={onClickBackButton} sx={{ color: 'primary.main' }}>
                Back
              </Button>
            )}
            <Button
              onClick={onClickNext}
              disabled={shouldDisableNextButton()}
              loading={isJdUploading}
            >
              Next
            </Button>
          </Stack>
        )}
      </Stack>
    </Stack>
  );
};

export default AiCustomizedResume;
