import { create } from 'zustand';

export enum DraftType {
  'COMPOSE' = 'compose',
  'GENERATE' = 'generate',
  'AUTOFIX' = 'autofix',
  'SUPERCHARGE' = 'supercharge',
}
export enum AutoFixModes {
  Boring = 'boring',
  Number = 'number',
  Fun = 'fun',
}

export interface Draft {
  id: string;
  text: string;
  type: DraftType;
  status: 'loading' | 'success' | 'error';
  mode?: AutoFixModes;
}

interface DraftsState {
  drafts: Draft[];
  maxDrafts: number;
  isLoading: boolean;
  setLoading: (isLoading: boolean) => void;
  setDrafts: (drafts: Draft[]) => void;
  setMaxDrafts: (maxDrafts: number) => void;
  resetDrafts: () => void;
  addDraft: (draft: Draft) => void;
  updateDraft: (id: string, draft: Partial<Draft>) => void;
  removeDraft: (id: string) => void;
  getDraft: (id: string) => Draft | undefined;
}

const useDraftsStore = create<DraftsState>((set) => ({
  drafts: [],
  maxDrafts: 3,
  isLoading: false,
  setLoading: (isLoading) => set({ isLoading }),
  setDrafts: (drafts) =>
    set((state) => ({
      drafts: drafts.slice(0, state.maxDrafts),
    })),
  setMaxDrafts: (maxDrafts) => set({ maxDrafts }),
  addDraft: (draft: Draft) => {
    // check if draft already exists then update it instead of adding a new one
    const existingDraft = useDraftsStore.getState().drafts.find((d) => d.id === draft.id);
    if (existingDraft) {
      useDraftsStore.getState().updateDraft(draft.id, draft);
      return;
    }
    set((state) => {
      const newDrafts = [...state.drafts, draft];
      return {
        drafts: newDrafts.slice(0, state.maxDrafts),
      };
    });
  },
  updateDraft(id, draft) {
    // check if draft does not exist then add it instead of updating
    const existingDraft = useDraftsStore.getState().drafts.find((d) => d.id === id);
    if (!existingDraft) {
      useDraftsStore.getState().addDraft(draft as Draft);
      return;
    }
    set((state) => ({
      drafts: state.drafts.map((d) => (d.id === id ? { ...d, ...draft } : d)),
    }));
  },
  removeDraft: (id: string) =>
    set((state) => ({
      drafts: state.drafts.filter((draft) => draft.id !== id),
    })),
  resetDrafts: () => set({ drafts: [] }),
  getDraft: (id: string): Draft | undefined =>
    useDraftsStore.getState().drafts.find((draft) => draft.id === id),
}));

// might need to throttle this later
// Subscribe to state changes and set loading state if there are any drafts in loading state
useDraftsStore.subscribe((state) => {
  const hasLoadingDrafts = state.drafts.some((draft) => draft.status === 'loading');
  if (hasLoadingDrafts && !state.isLoading) {
    useDraftsStore.getState().setLoading(true);
  }

  if (!hasLoadingDrafts && state.isLoading) {
    useDraftsStore.getState().setLoading(false);
  }
});

export default useDraftsStore;
