import { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';

import { Box, Typography, useTheme } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

import { isNotNilOrEmpty } from '../../../utils';
import SelectCard from '../../common/SelectCard';
import { AddResumeOptions, SelectResumeSection } from '../constants';
import type { ImportResumeProps } from './ImportResume';
import type { SelectResumeProps } from './SelectResume';
import SelectResume from './SelectResume';

// TODO: use map instead of direct index access
interface ResumeMobileSelectorProps {
  selectResumeProps: SelectResumeProps;
  importResumeProps: ImportResumeProps;
  value: {
    activeSection: SelectResumeSection;
    resumeId: string;
    resumeFile: File | undefined;
  };
  setSelectedSection: (section: SelectResumeSection) => void;
}

/**
 * The `ResumeMobileSelector` component allows users to select or import a resume on mobile devices.
 * It displays options for selecting an existing resume or importing a new one.
 * The component uses a card layout for each option and a dropzone for file uploads.
 *
 * @component
 *
 * @param {ResumeMobileSelectorProps} props - The properties for the `ResumeMobileSelector` component.
 * @param {SelectResumeProps} props.selectResumeProps - The properties for selecting an existing resume.
 * @param {ImportResumeProps} props.importResumeProps - The properties for importing a new resume.
 * @param {Object} props.value - The current value of the resume selection.
 * @param {SelectResumeSection} props.value.activeSection - The active section of the resume selection.
 * @param {string} props.value.resumeId - The ID of the selected resume.
 * @param {File | undefined} props.value.resumeFile - The imported resume file.
 * @param {(section: SelectResumeSection) => void} props.setSelectedSection - Function to set the selected section.
 *
 * @returns {JSX.Element} The rendered `ResumeMobileSelector` component.
 *
 * @example
 * <ResumeMobileSelector
 *   selectResumeProps={{
 *     resumeList: resumes,
 *     onSelectResume: handleSelectResume,
 *     isLoading: false,
 *     defaultValue: 'resume1',
 *     isSectionEnabled: true,
 *   }}
 *   importResumeProps={{
 *     onImport: handleImportResume,
 *     defaultValue: null,
 *     isSectionEnabled: true,
 *   }}
 *   value={{
 *     activeSection: SelectResumeSection.SELECT_RESUME,
 *     resumeId: 'resume1',
 *     resumeFile: null,
 *   }}
 *   setSelectedSection={(section) => console.log('Selected section:', section)}
 * />
 */
const ResumeMobileSelector = ({
  selectResumeProps,
  importResumeProps,
  setSelectedSection,
  value,
}: ResumeMobileSelectorProps) => {
  const theme = useTheme();
  const [isCardSelected, setIsCardSelected] = useState(false);

  const handleCardClick = (key: string) => {
    setIsCardSelected(true);
    setSelectedSection(key as SelectResumeSection);
  };

  const onFileChange = useCallback(
    (uploadedFile: File[]) => {
      importResumeProps.onImport({ resumeFile: uploadedFile[0] });
    },
    [importResumeProps],
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: onFileChange,
    multiple: false,
    accept: {
      'application/pdf': ['.pdf'],
    },
  });

  return (
    <>
      <Grid spacing={2} container sx={{ justifyContent: 'center', width: '100%', margin: 0 }}>
        <Grid xs={12} sx={{ paddingTop: 0, paddingBottom: 3 }}>
          <Typography variant="h3" sx={{ textAlign: { xs: 'center', md: 'left' } }}>
            Add Resume
          </Typography>
        </Grid>
        <Grid
          key={AddResumeOptions[0].key}
          xs={12}
          md={6}
          lg={4}
          sx={{ [theme.breakpoints.down('md')]: { paddingX: 0 } }}
        >
          <SelectCard
            cardContent={AddResumeOptions[0]}
            sx={{
              borderColor:
                value.activeSection === SelectResumeSection.SELECT_RESUME && value.resumeId !== ''
                  ? 'primary.main'
                  : 'border.light',
            }}
            onClick={handleCardClick}
            enableDescription={value.resumeId !== ''}
          />
        </Grid>
        <Grid
          key={AddResumeOptions[1].key}
          xs={12}
          md={6}
          lg={4}
          sx={{ [theme.breakpoints.down('md')]: { paddingX: 0 } }}
        >
          <Box {...getRootProps()}>
            <input {...getInputProps()} />
            <SelectCard
              cardContent={AddResumeOptions[1]}
              sx={{
                borderColor:
                  value.activeSection === SelectResumeSection.IMPORT_RESUME &&
                  isNotNilOrEmpty(value.resumeFile)
                    ? 'primary.main'
                    : 'border.light',
              }}
              onClick={handleCardClick}
              enableDescription={isNotNilOrEmpty(value.resumeFile)}
            />
          </Box>
        </Grid>
      </Grid>
      <SelectResume
        {...selectResumeProps}
        onClose={() => setIsCardSelected(false)}
        onSelectResume={selectResumeProps.onSelectResume}
        isSectionEnabled={selectResumeProps.isSectionEnabled && isCardSelected}
      />
    </>
  );
};

export default ResumeMobileSelector;
