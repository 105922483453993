export enum SelectResumeSection {
  SELECT_RESUME = 'select-resume',
  IMPORT_RESUME = 'import-resume',
}

export const AddResumeOptions = [
  {
    key: SelectResumeSection.SELECT_RESUME,
    icon: 'fi-rr-document-signed',
    heading: 'Select a Resume',
    description: '',
  },
  {
    key: SelectResumeSection.IMPORT_RESUME,
    icon: 'fi-rr-upload',
    heading: 'Import a Resume',
    description: '',
  },
];
