import parse from 'html-react-parser';

import { CircularProgress, Stack, Typography, useTheme } from '@mui/material';

import type { Draft as DraftDataType } from '../../../../../stores/SuperEditorDrafts';
import { getDraftTitleText, getDraftsIcon } from '../../../../../utils';
import Button from '../../../../common/Button';
import Icon from '../../../../common/Icon';

/**
 * Props for the `Draft` component.
 */
interface DraftProps {
  /** The draft data to be displayed. */
  draft: DraftDataType;

  /** The title of the section the draft belongs to. */
  sectionTitle: string; // TODO: update draft to accept enum type instead of string (super-charge, auto-fix, compose)

  /**
   * Optional handler for appending the content with the draft content.
   * @param draft - The draft to append.
   */
  onAppendDraft?: (draft: DraftDataType) => void;

  /**
   * Optional handler for replacing the content with the draft content.
   * @param draft - The draft to replace.
   */
  onReplaceDraft?: (draft: DraftDataType) => void;

  /**
   * Handler for deleting the draft.
   * @param id - The ID of the draft to delete.
   */
  onDelete: (id: string) => void;
}

/**
 * The `Draft` component renders a draft with its status, title, text, and actions (Append, Replace, Delete).
 *
 * @param {DraftProps} props - Props to configure the `Draft` component.
 * @param {DraftDataType} props.draft - The draft data to be displayed.
 * @param {string} props.sectionTitle - The title of the section the draft belongs to.
 * @param {(draft: DraftDataType) => void} [props.onAppendDraft] - Optional handler for appending the content with the draft content.
 * @param {(draft: DraftDataType) => void} [props.onReplaceDraft] - Optional handler for replacing the content with the draft content.
 * @param {(id: string) => void} props.onDelete - Handler for deleting the draft.
 *
 * @returns {JSX.Element} The rendered `Draft` component.
 *
 * @example
 * <Draft
 *   draft={draftData}
 *   sectionTitle="AutoFix"
 *   onAppendDraft={(draft) => console.log('Append:', draft)}
 *   onReplaceDraft={(draft) => console.log('Replace:', draft)}
 *   onDelete={(id) => console.log('Delete:', id)}
 * />
 */
const Draft = ({ draft, sectionTitle, onAppendDraft, onReplaceDraft, onDelete }: DraftProps) => {
  const theme = useTheme(); // Access theme for styling purposes.

  return (
    <Stack
      key={draft.id}
      sx={{
        width: '100%',
        height: '100%',
        padding: 1.5,
        gap: 1.5,
        border: '1px solid',
        borderColor: 'border.light',
        borderRadius: 2,
        boxSizing: 'border-box',
        justifyContent: 'space-between',
      }}
    >
      <Stack sx={{ gap: 1.5 }}>
        {/* Header with draft status icon and title */}
        <Stack flexDirection="row" gap={1}>
          {draft.status === 'loading' && (
            <CircularProgress size={16} value={50} variant="indeterminate" color="success" />
          )}
          {draft.status !== 'loading' && (
            <Icon
              className={getDraftsIcon(draft.type)}
              style={{ fontSize: '16px', color: theme.palette.text.secondary }}
            />
          )}
          <Typography variant="assistiveBold">
            {getDraftTitleText(draft.type, draft.status, sectionTitle, draft.mode)}
          </Typography>
        </Stack>

        {/* Draft content text */}
        <Typography variant="body3">{parse(draft.text)}</Typography>
      </Stack>
      {/* Action buttons for the draft (Append, Replace, Delete) */}
      {draft.status !== 'loading' && (
        <Stack
          sx={{
            flexDirection: 'row',
            justifyContent: 'center',
            marginTop: 2.5,
            gap: 1.5,
          }}
        >
          {/* Append Button */}
          <Button
            variant="outlined"
            sx={{
              color: 'text.primary',
              borderColor: 'border.main',
            }}
            onClick={() => onAppendDraft && onAppendDraft(draft)}
          >
            Append
          </Button>

          {/* Replace Button */}
          <Button
            variant="outlined"
            sx={{
              color: 'text.primary',
              borderColor: 'border.main',
            }}
            onClick={() => onReplaceDraft && onReplaceDraft(draft)}
          >
            Replace
          </Button>

          {/* Delete Button */}
          <Button
            variant="outlined"
            sx={{
              minWidth: 'unset',
              color: 'text.primary',
              borderColor: 'border.main',
            }}
            onClick={() => onDelete(draft.id)}
          >
            <Icon className="fi fi-rr-trash" fontSize="16px" color={theme.palette.text.secondary} />
          </Button>
        </Stack>
      )}
    </Stack>
  );
};

export default Draft;
