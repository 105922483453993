import { useMemo, useState } from 'react';

import { useQuery } from '@tanstack/react-query';

import useResponsiveDevice from '../../hooks/useResponsiveDevice';
import { ResumesKeys, getAllProcessedResumes } from '../../services/resumes';
import ResumeDesktopSelector from './components/ResumeDesktopSelector';
import ResumeMobileSelector from './components/ResumeMobileSelector';
import { SelectResumeSection } from './constants';

interface ResumeSelectorProps {
  onUpdate: (data: { resumeId?: string; resumeFile?: File }) => void;
  value: {
    activeSection: SelectResumeSection;
    resumeId: string;
    resumeFile: File | undefined;
  };
}

/**
 * The `ResumeSelector` component allows users to select or import a resume.
 * It adapts its layout based on the device type, displaying a different UI for mobile and desktop views.
 *
 * @component
 *
 * @param {ResumeSelectorProps} props - The props for the `ResumeSelector` component.
 * @param {(data: { resumeId?: string; resumeFile?: File }) => void} props.onUpdate - Callback function invoked when a resume is selected or imported.
 * @param {Object} props.value - The current value of the resume selection.
 * @param {SelectResumeSection} props.value.activeSection - The active section of the resume selection.
 * @param {string} props.value.resumeId - The ID of the selected resume.
 * @param {File | undefined} props.value.resumeFile - The imported resume file.
 *
 * @returns {JSX.Element} The rendered `ResumeSelector` component.
 *
 * @example
 * <ResumeSelector
 *   onUpdate={(data) => console.log('Resume updated:', data)}
 *   value={{
 *     activeSection: SelectResumeSection.SELECT_RESUME,
 *     resumeId: '123',
 *     resumeFile: null,
 *   }}
 * />
 */
const ResumeSelector = ({ onUpdate, value }: ResumeSelectorProps) => {
  const { isMobile } = useResponsiveDevice();
  const [selectedSection, setSelectedSection] = useState(value.activeSection);

  const { data: resumes, isLoading } = useQuery({
    queryKey: [ResumesKeys.RESUMES, 'processed'],
    queryFn: getAllProcessedResumes,
  });

  const resumeProps = useMemo(
    () => ({
      selectResumeProps: {
        resumeList: resumes,
        onSelectResume: onUpdate,
        isLoading,
        defaultValue: value?.resumeId,
        isSectionEnabled: selectedSection === SelectResumeSection.SELECT_RESUME,
      },
      importResumeProps: {
        onImport: onUpdate,
        defaultValue: value?.resumeFile || undefined,
        isSectionEnabled: selectedSection === SelectResumeSection.IMPORT_RESUME,
      },
    }),
    [isLoading, onUpdate, resumes, selectedSection, value?.resumeFile, value?.resumeId],
  );

  return isMobile ? (
    <ResumeMobileSelector {...resumeProps} value={value} setSelectedSection={setSelectedSection} />
  ) : (
    <ResumeDesktopSelector
      {...resumeProps}
      selectedSection={selectedSection}
      setSelectedSection={setSelectedSection}
    />
  );
};

export default ResumeSelector;
