import {
  Chip,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from '@mui/material';

import type { JobDescriptionXRayListResult } from '../../../services/jobDescriptions';
import theme from '../../../theme/theme';
import LoadingSpinner from '../../common/LoadingSpinner';

interface SelectJobXrayContentProps {
  isLoading: boolean;
  jobDescriptionList: JobDescriptionXRayListResult[] | undefined;
  jobDescriptionId: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  defaultValue: string;
}

const SelectJobXrayContent = ({
  isLoading,
  jobDescriptionList,
  jobDescriptionId,
  onChange,
  defaultValue,
}: SelectJobXrayContentProps) => (
  <>
    {isLoading ? (
      <LoadingSpinner />
    ) : (
      <FormControl sx={{ width: '100%' }}>
        <RadioGroup
          name="select-job-descriptions-group"
          onChange={onChange}
          defaultValue={defaultValue}
        >
          {jobDescriptionList?.map((jobDescription) => (
            <FormControlLabel
              key={jobDescription._id}
              value={jobDescription._id}
              control={<Radio sx={{ padding: 0 }} />}
              label={
                <Stack
                  sx={{
                    flexDirection: 'row',
                    alignItems: 'center',
                    gap: 0.75,
                    overflow: 'hidden',
                  }}
                >
                  <Chip
                    label={
                      <Typography
                        variant={
                          jobDescriptionId === jobDescription._id ? 'assistiveBold' : 'assistive'
                        }
                      >
                        {jobDescription['job-description-xray'].company || '-'}
                      </Typography>
                    }
                    sx={{
                      ...(jobDescriptionId === jobDescription._id
                        ? {
                            backgroundColor: 'primary.mediumLight',
                            color: 'primary.mediumDark',
                          }
                        : {
                            backgroundColor: 'background.main',
                            color: 'inherit',
                          }),
                      borderRadius: 4,
                      height: theme.spacing(3),
                      '& .MuiChip-label': {
                        padding: `${theme.spacing(0)} ${theme.spacing(1)}`,
                      },
                    }}
                  />
                  <Typography
                    variant="body3"
                    sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
                  >
                    {jobDescription['job-description-xray'].title || '-'}
                  </Typography>
                </Stack>
              }
              sx={{
                gap: 1,
                border: 'none',
                borderRadius: 2,
                margin: 0,
                padding: `${theme.spacing(2)} ${theme.spacing(1.5)}`,
                ':hover': {
                  backgroundColor: 'background.main',
                },
                backgroundColor:
                  jobDescriptionId === jobDescription._id ? 'primary.light' : 'common.white',
                width: '100%',
                boxSizing: 'border-box',
                overflow: 'hidden',
                '& .MuiFormControlLabel-label': {
                  overflow: 'hidden',
                },
              }}
            />
          ))}
        </RadioGroup>
      </FormControl>
    )}
  </>
);

export default SelectJobXrayContent;
