import { useCallback, useState } from 'react';

import { Stack, useTheme } from '@mui/material';

import useResponsiveDevice from '../../../hooks/useResponsiveDevice';
import Button from '../../common/Button';
import Modal from '../../common/Modal/Modal';
import ModalActions from '../../common/Modal/ModalActions';
import ModalDetail from '../../common/Modal/ModalDetail';
import ImportResumeContent from './ImportResumeContent';

export interface ImportResumeProps {
  onImport: (data: { resumeFile?: File }) => void;
  defaultValue?: File;
  isSectionEnabled?: boolean;
}

/**
 * A component that allows users to import a resume file.
 * It adapts its layout based on the device type, displaying a modal on desktop view
 * and a swipeable drawer on mobile. The user can import a resume file and view
 * information about what a base resume is in a modal.
 *
 * @component
 *
 * @param {ImportResumeProps} props - The properties for the ImportResume component.
 * @param {(data: { resumeFile?: File }) => void} props.onImport - Callback function invoked when a resume file is imported, receiving the resume file.
 * @param {() => void} [props.onClose] - Optional callback triggered when the swipeable drawer or modal is closed.
 * @param {File} [props.defaultValue] - Default resume file to be displayed.
 * @param {boolean} [props.isSectionEnabled=false] - Flag that controls whether the section is enabled and visible. Defaults to `false`.
 *
 * @returns {JSX.Element | null} The rendered JSX element, or `null` if `isSectionEnabled` is `false`.
 *
 * @example
 * const handleImport = (data: { resumeFile?: File }) => {
 *   console.log('Imported Resume File:', data.resumeFile);
 * };
 *
 * <ImportResume
 *   onImport={handleImport}
 *   defaultValue={new File([''], 'sample.pdf', { type: 'application/pdf' })}
 *   isSectionEnabled={true}
 * />;
 */

const ImportResume = ({
  onImport,
  defaultValue = undefined,
  isSectionEnabled = false,
}: ImportResumeProps) => {
  const theme = useTheme();
  const { isMobile } = useResponsiveDevice();
  const [showPopup, setShowPopup] = useState(false);
  const [importResume, setImportResume] = useState(defaultValue);

  const handleResumeChange = useCallback(
    (file: File | undefined) => {
      if (!isMobile) {
        onImport({ resumeFile: file });
      }
      setImportResume(file);
    },
    [isMobile, onImport],
  );

  return isSectionEnabled ? (
    <Stack
      sx={{
        border: `${theme.spacing(0.125)} solid`,
        borderColor: 'border.light',
        borderRadius: `${theme.spacing(0)} ${theme.spacing(0)} 
                               ${theme.spacing(2)} ${theme.spacing(2)}`,
        padding: `${theme.spacing(3)} ${theme.spacing(4)} 
                          ${theme.spacing(4)} ${theme.spacing(6)}`,
        minHeight: '45vh',
        boxSizing: 'border-box',
      }}
    >
      <ImportResumeContent
        resumeFile={importResume || undefined}
        onShowExample={() => setShowPopup(true)}
        onResumeChange={handleResumeChange}
      />
      <Modal open={showPopup} contentSx={{ width: '80vw', maxWidth: '31.25rem' }}>
        <>
          <ModalDetail
            title="What is a Base Resume?"
            description="Importing a new resume creates a base resume that you can duplicate, edit, AI customize, and download. Using the base resume, you can easily create many versions of resumes for different job positions. Avoid importing the same resume twice."
          />
          <ModalActions
            actions={
              <Button color="primary" onClick={() => setShowPopup(false)}>
                Got it
              </Button>
            }
          />
        </>
      </Modal>
    </Stack>
  ) : null;
};

export default ImportResume;
