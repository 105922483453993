import {
  Chip,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from '@mui/material';

import type { Resume } from '../../../services/resumes';
import theme from '../../../theme/theme';
import LoadingSpinner from '../../common/LoadingSpinner';

interface SelectResumeContentProps {
  isLoading: boolean;
  resumeList: Resume[] | undefined;
  resumeId: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  defaultValue: string;
}

/**
 * The `SelectResumeContent` component renders a list of resumes for selection.
 * It displays a loading spinner while the resumes are being fetched and shows
 * a list of radio buttons for each resume once the data is available.
 *
 * @component
 *
 * @param {SelectResumeContentProps} props - The props for the `SelectResumeContent` component.
 * @param {boolean} props.isLoading - Flag to indicate if the resumes are still loading.
 * @param {Resume[] | undefined} props.resumeList - The list of resumes available for selection.
 * @param {string} props.resumeId - The ID of the selected resume.
 * @param {(event: React.ChangeEvent<HTMLInputElement>) => void} props.onChange - Callback function invoked when a resume is selected.
 * @param {string} props.defaultValue - Default selected resume ID.
 *
 * @returns {JSX.Element} The rendered `SelectResumeContent` component.
 *
 * @example
 * const resumes = [
 *   { _id: 'resume1', name: 'Resume 1', filename: 'resume1.pdf', is_ai_customized: false, is_ai_generated: false },
 *   { _id: 'resume2', name: 'Resume 2', filename: 'resume2.pdf', is_ai_customized: true, is_ai_generated: false },
 * ];
 *
 * <SelectResumeContent
 *   isLoading={false}
 *   resumeList={resumes}
 *   resumeId="resume1"
 *   onChange={(event) => console.log('Selected resume ID:', event.target.value)}
 *   defaultValue="resume1"
 * />
 */
const SelectResumeContent = ({
  isLoading,
  resumeList,
  resumeId,
  onChange,
  defaultValue,
}: SelectResumeContentProps) => (
  <>
    {isLoading ? (
      <LoadingSpinner />
    ) : (
      <FormControl sx={{ width: '100%' }}>
        <RadioGroup
          name="select-resume-sections-group"
          onChange={onChange}
          defaultValue={defaultValue}
        >
          {resumeList?.map((resume) => (
            <FormControlLabel
              key={resume._id}
              value={resume._id}
              control={<Radio sx={{ padding: 0 }} />}
              label={
                <Stack
                  sx={{
                    flexDirection: 'row',
                    alignItems: 'center',
                    gap: 0.75,
                    overflow: 'hidden',
                  }}
                >
                  {(resume.is_ai_customized || resume.is_ai_generated) && (
                    <Chip
                      sx={{
                        ...(resumeId === resume._id
                          ? {
                              backgroundColor: 'primary.mediumLight',
                              color: 'primary.mediumDark',
                            }
                          : {
                              backgroundColor: 'background.main',
                              color: 'inherit',
                            }),
                        borderRadius: 4,
                        height: theme.spacing(3),
                        '& .MuiChip-label': {
                          padding: `${theme.spacing(0)} ${theme.spacing(1)}`,
                        },
                      }}
                      label={resume.is_ai_customized ? 'AI Customized' : 'AI Sample'}
                    />
                  )}
                  <Typography variant="body3">
                    {resume.name || resume.company_customized_for || 'Base'}
                  </Typography>
                  <Typography
                    variant="body3"
                    color="text.secondary"
                    sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
                  >
                    {resume.filename || '-'}
                  </Typography>
                </Stack>
              }
              sx={{
                gap: 1,
                border: 'none',
                borderRadius: 2,
                margin: 0,
                padding: `${theme.spacing(2)} ${theme.spacing(1.5)}`,
                ':hover': {
                  backgroundColor: 'background.main',
                },
                backgroundColor: resumeId === resume._id ? 'primary.light' : 'common.white',
                width: '100%',
                boxSizing: 'border-box',
                overflow: 'hidden',
                '& .MuiFormControlLabel-label': {
                  overflow: 'hidden',
                },
              }}
            />
          ))}
        </RadioGroup>
      </FormControl>
    )}
  </>
);

export default SelectResumeContent;
