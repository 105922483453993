import type { ChangeEvent } from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { Box, Typography, useTheme } from '@mui/material';

import useResponsiveDevice from '../../../hooks/useResponsiveDevice';
import type { Resume } from '../../../services/resumes';
import Button from '../../common/Button';
import Card from '../../common/Card';
import SwipeableDrawer, { Puller } from '../../common/SwipeableDrawer';
import SelectResumeContent from './SelectResumeContent';

export interface SelectResumeProps {
  resumeList: Resume[] | undefined;
  onSelectResume: ({ resumeId }: { resumeId: string }) => void;
  onClose?: () => void;
  isLoading?: boolean;
  defaultValue?: string;
  isSectionEnabled?: boolean;
}

/**
 * The `SelectResume` component allows users to select a resume from a list of available options.
 * It adapts its layout based on the device type, showing a card for desktop view
 * and a swipeable drawer for mobile devices. Ideal for scenarios where users need to pick
 * a resume in a responsive manner.
 *
 * @component
 *
 * @param {SelectResumeProps} props - The properties for the `SelectResume` component.
 * @param {Resume[] | undefined} props.resumeList - The list of resumes available for selection.
 * @param {(params: { resumeId: string }) => void} props.onSelectResume - Callback function invoked when a resume is selected, receiving the selected resume ID.
 * @param {() => void} [props.onClose] - Optional callback triggered when the swipeable drawer is closed.
 * @param {boolean} [props.isLoading=false] - Flag to indicate if the resumes are still loading. Defaults to `false`.
 * @param {string} [props.defaultValue=""] - Default selected resume ID.
 * @param {boolean} [props.isSectionEnabled=false] - Flag that controls if the section is enabled and visible. Defaults to `false`.
 *
 * @returns {JSX.Element | null} The rendered `SelectResume` component, or `null` if `isSectionEnabled` is `false`.
 *
 * @example
 * const resumes = [
 *   { _id: 'resume1', name: 'Resume 1', filename: 'resume1.pdf', is_ai_customized: false, is_ai_generated: false },
 *   { _id: 'resume2', name: 'Resume 2', filename: 'resume2.pdf', is_ai_customized: true, is_ai_generated: false },
 * ];
 *
 * const handleSelectResume = ({ resumeId }: { resumeId: string }) => {
 *   console.log(`Selected Resume ID: ${resumeId}`);
 * };
 *
 * <SelectResume
 *   resumeList={resumes}
 *   onSelectResume={handleSelectResume}
 *   isLoading={false}
 *   defaultValue="resume1"
 *   isSectionEnabled={true}
 * />
 */
const SelectResume = ({
  resumeList,
  onSelectResume,
  onClose,
  isLoading = false,
  defaultValue = '',
  isSectionEnabled = false,
}: SelectResumeProps) => {
  const theme = useTheme();
  const { isMobile } = useResponsiveDevice();
  const [open, setOpen] = useState<boolean>(isSectionEnabled);
  const [resumeId, setResumeId] = useState(defaultValue);

  useEffect(() => {
    if (isMobile) {
      setOpen(isSectionEnabled);
    }
  }, [isMobile, isSectionEnabled]);

  const onCloseDrawer = () => {
    setOpen(false);
    if (onClose) {
      onClose();
    }
  };

  const onSelect = () => {
    onSelectResume({ resumeId });
  };

  const onChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (!isMobile) {
        onSelectResume({ resumeId: event.target.value });
      }
      setResumeId(event.target.value);
    },
    [isMobile, onSelectResume],
  );

  const children = useMemo(
    () =>
      SelectResumeContent({
        isLoading,
        resumeList,
        resumeId,
        onChange,
        defaultValue,
      }),
    [isLoading, resumeList, resumeId, onChange, defaultValue],
  );

  // Render Card for desktop version and SwipeableDrawer for mobile version
  return isSectionEnabled ? (
    <>
      {!isMobile ? (
        <Card
          sx={{
            height: '45vh',
            boxSizing: 'border-box',
            overflowY: 'scroll',
          }}
          cardContentProps={{
            sx: {
              padding: `${theme.spacing(1)} ${theme.spacing(2)}
                            ${theme.spacing(1)} ${theme.spacing(4)}`,
              height: '100%',
              boxSizing: 'border-box',
            },
          }}
          rootSx={{ width: '-webkit-fill-available' }}
        >
          {children}
        </Card>
      ) : (
        <SwipeableDrawer
          open={open}
          onClose={onCloseDrawer}
          onOpen={() => setOpen(true)}
          contentProps={{
            sx: {
              maxWidth: '100vw',
              height: '85vh',
              textAlign: 'left',
              paddingBottom: 3,
              boxSizing: 'border-box',
            },
          }}
        >
          <Puller />
          <Typography variant="h3" sx={{ width: '100%', paddingTop: 3, paddingBottom: 2 }}>
            Selecting Resume
          </Typography>
          <Box
            sx={{
              width: '100%',
              height: `calc(85vh - ${theme.spacing(20)})`,
              overflowY: 'scroll',
              overflowX: 'hidden',
            }}
          >
            {children}
          </Box>
          <Button sx={{ width: '100%', padding: 2, marginTop: 1 }} onClick={onSelect}>
            Select
          </Button>
        </SwipeableDrawer>
      )}
    </>
  ) : null;
};

export default SelectResume;
