import type { ChangeEvent } from 'react';

import {
  FormControl,
  FormControlLabel as MuiFormControlLabel,
  Radio as MuiRadio,
  RadioGroup,
  Typography,
  useTheme,
} from '@mui/material';
import { styled } from '@mui/material/styles';

import { SelectResumeSection } from '../constants';
import type { ImportResumeProps } from './ImportResume';
import ImportResume from './ImportResume';
import type { SelectResumeProps } from './SelectResume';
import SelectResume from './SelectResume';

const Radio = styled(MuiRadio)(({ theme: themeConfigs }) => ({
  width: themeConfigs.spacing(2.25),
  height: themeConfigs.spacing(2.25),
}));

// TODO: reuse this styled component in other files
export const FormControlLabel = styled(MuiFormControlLabel)(({ theme: themeConfigs }) => ({
  border: `${themeConfigs.spacing(0.125)} solid ${
    (themeConfigs.palette.border as { light?: string })?.light
  }`,
  backgroundColor: (themeConfigs.palette.background as { mediumLight?: string })?.mediumLight || '',
  borderRadius: `${themeConfigs.spacing(2)} ${themeConfigs.spacing(2)} 
                 ${themeConfigs.spacing(0)} ${themeConfigs.spacing(0)}`,
  boxSizing: 'border-box',
  margin: 0,
  gap: themeConfigs.spacing(2),
  padding: themeConfigs.spacing(2),
}));

interface ResumeDesktopSelectorProps {
  selectResumeProps: SelectResumeProps;
  importResumeProps: ImportResumeProps;
  selectedSection: SelectResumeSection;
  setSelectedSection: (section: SelectResumeSection) => void;
}

/**
 * The `ResumeDesktopSelector` component allows users to select or import a resume on desktop devices.
 * It displays options for selecting an existing resume or importing a new one using radio buttons.
 * The component uses a form control layout for each option.
 *
 * @component
 *
 * @param {ResumeDesktopSelectorProps} props - The properties for the `ResumeDesktopSelector` component.
 * @param {SelectResumeProps} props.selectResumeProps - The properties for selecting an existing resume.
 * @param {ImportResumeProps} props.importResumeProps - The properties for importing a new resume.
 * @param {SelectResumeSection} props.selectedSection - The currently selected section.
 * @param {(section: SelectResumeSection) => void} props.setSelectedSection - Function to set the selected section.
 *
 * @returns {JSX.Element} The rendered `ResumeDesktopSelector` component.
 *
 * @example
 * <ResumeDesktopSelector
 *   selectResumeProps={{
 *     resumeList: resumes,
 *     onSelectResume: handleSelectResume,
 *     isLoading: false,
 *     defaultValue: 'resume1',
 *     isSectionEnabled: true,
 *   }}
 *   importResumeProps={{
 *     onImport: handleImportResume,
 *     defaultValue: null,
 *     isSectionEnabled: true,
 *   }}
 *   selectedSection={SelectResumeSection.SELECT_RESUME}
 *   setSelectedSection={(section) => console.log('Selected section:', section)}
 * />
 */
const ResumeDesktopSelector = ({
  selectResumeProps,
  importResumeProps,
  selectedSection,
  setSelectedSection,
}: ResumeDesktopSelectorProps) => {
  const theme = useTheme();

  const handleSelectedSectionChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSelectedSection(event.target.value as SelectResumeSection);
  };

  return (
    <FormControl sx={{ height: '100%' }}>
      <RadioGroup
        defaultValue={selectedSection}
        name="select-jd-sections-group"
        onChange={handleSelectedSectionChange}
        sx={{ height: '100%' }}
      >
        <FormControlLabel
          value={SelectResumeSection.SELECT_RESUME}
          control={<Radio />}
          label={<Typography variant="label2">Select a Resume</Typography>}
          sx={{
            backgroundColor:
              selectedSection === SelectResumeSection.SELECT_RESUME
                ? 'background.mediumLight'
                : 'common.white',
            width: '-webkit-fill-available',
          }}
        />
        {/* Select Resume section goes here */}
        <SelectResume {...selectResumeProps} />
        <FormControlLabel
          value={SelectResumeSection.IMPORT_RESUME}
          control={<Radio />}
          label={<Typography variant="label2">Import New Resume</Typography>}
          sx={{
            ...(selectedSection === SelectResumeSection.IMPORT_RESUME
              ? {
                  borderRadius: 0,
                  backgroundColor: 'background.mediumLight',
                }
              : {
                  borderRadius: `${theme.spacing(0)} ${theme.spacing(0)} 
                                   ${theme.spacing(2)} ${theme.spacing(2)}`,
                  backgroundColor: 'common.white',
                }),
          }}
        />
        {/* Import resume goes here */}
        <ImportResume {...importResumeProps} />
      </RadioGroup>
    </FormControl>
  );
};

export default ResumeDesktopSelector;
