import { Box, FormHelperText } from '@mui/material';

import useResponsiveDevice from '../../../hooks/useResponsiveDevice';
import FileDropzone from '../../FileDropzone';
import TextWrappedLinkButton from '../../common/TextWrappedLinkButton';

interface ImportResumeContentProps {
  resumeFile?: File | undefined;
  onShowExample: () => void;
  onResumeChange: (file: File | undefined) => void;
}

/**
 * The `ImportResumeContent` component provides a UI for importing a resume file.
 * It includes a file dropzone for uploading the resume and a link button to show information about base resumes.
 *
 * @component
 *
 * @param {ImportResumeContentProps} props - The properties for the `ImportResumeContent` component.
 * @param {File | undefined} props.resumeFile - The currently selected resume file.
 * @param {() => void} props.onShowExample - Callback function invoked when the "What is a base resume?" link is clicked.
 * @param {(file: File | undefined) => void} props.onResumeChange - Callback function invoked when a resume file is selected or changed.
 *
 * @returns {JSX.Element} The rendered `ImportResumeContent` component.
 *
 * @example
 * <ImportResumeContent
 *   resumeFile={selectedFile}
 *   onShowExample={() => console.log('Show base resume example')}
 *   onResumeChange={(file) => console.log('Selected resume file:', file)}
 * />
 */
const ImportResumeContent = ({
  resumeFile,
  onShowExample,
  onResumeChange,
}: ImportResumeContentProps) => {
  const { isMobile } = useResponsiveDevice();

  return (
    <>
      <FormHelperText sx={{ margin: 0, marginBottom: 1 }}>
        <TextWrappedLinkButton
          variant="body3"
          text={{
            startingText: 'Importing a new resume creates a base resume. ',
            linkText: 'What is a base resume?',
            endingText: '',
          }}
          sx={{ ...(isMobile && { color: 'text.secondary', fontWeight: 700 }) }}
          onClick={onShowExample}
        />
      </FormHelperText>
      {/* Resume File input field*/}
      <Box sx={{ width: '100%', height: '100%' }}>
        <FileDropzone hideLabel onFileChange={onResumeChange} defaultValue={resumeFile} />
      </Box>
    </>
  );
};

export default ImportResumeContent;
